import CloseIcon from '@mui/icons-material/Close';
import { Modal, Box, Typography, TextField, Button, IconButton } from '@mui/material';
import { captureException } from '@sentry/react';
import { intervalToDuration, parseISO } from 'date-fns';
import React, { useState, useMemo, useRef, useEffect } from 'react';
import {
  CustomTable,
  theme,
  TableProps,
  ClockIcon16,
  GreenLiveIcon16,
  LetterAvatar,
  SnackbarService,
} from '@stationwise/component-module';
import { client } from '@stationwise/share-api';
import { AutoHireInfoView, AutoHireHistoryView } from '@stationwise/share-types';
import { AutoHireStatus, DynamicTimeDisplay, getStatusChip } from '../utils';

interface AutoHireHistoryProps {
  open: boolean;
  onClose: () => void;
  autoHireInfo: AutoHireInfoView;
  rankId: number;
  forceRefetch: () => void;
}

export const AutoHireHistory: React.FC<AutoHireHistoryProps> = ({ open, onClose, autoHireInfo, rankId, forceRefetch }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const currentAutoHire = useMemo(() => {
    return autoHireInfo.autoHires.find((autoHire) => autoHire.positions.some((position) => position.rank.id === rankId));
  }, [autoHireInfo, rankId]);

  const filteredEmployees = useMemo(() => {
    if (!currentAutoHire) return [];
    return currentAutoHire.history.filter((historyEntry) =>
      historyEntry.employee.name.toLowerCase().includes(searchTerm.toLowerCase()),
    );
  }, [currentAutoHire, searchTerm]);

  const { currentEmployee, nextEmployee } = useMemo(() => {
    if (!currentAutoHire || !currentAutoHire.history.length) {
      return { currentEmployee: null, nextEmployee: null };
    }

    const sortedHistory = [...currentAutoHire.history].sort((a, b) => {
      if (!a.notificationSentTime) return 1;
      if (!b.notificationSentTime) return -1;
      return new Date(b.notificationSentTime).getTime() - new Date(a.notificationSentTime).getTime();
    });

    const currentEmployee = sortedHistory[0];
    const currentIndex = currentAutoHire.history.indexOf(currentEmployee);
    const nextEmployee = currentAutoHire.history[currentIndex + 1] || null;

    return { currentEmployee, nextEmployee };
  }, [currentAutoHire]);

  const columns: TableProps['columns'] = [
    { id: 'no', label: 'NO.', minWidth: 50, align: 'center' },
    { id: 'name', label: 'NAME', minWidth: 140, align: 'left' },
    { id: 'status', label: 'STATUS', minWidth: 120, align: 'center' },
    { id: 'timeElapsed', label: 'TIME ELAPSED', minWidth: 120, align: 'center' },
    { id: 'rank', label: 'RANK', minWidth: 100, align: 'center' },
    { id: 'battalion', label: 'BATTALION', minWidth: 100, align: 'center' },
    { id: 'station', label: 'STATION', minWidth: 100, align: 'center' },
    { id: 'apparatus', label: 'APPARATUS', minWidth: 100, align: 'center' },
  ];

  const getStatus = (historyEntry: AutoHireHistoryView): AutoHireStatus => {
    if (historyEntry.position) return AutoHireStatus.VACANCY_FILLED;
    if (historyEntry.isSkipped) return AutoHireStatus.SKIPPED;
    if (historyEntry === currentEmployee && historyEntry.notificationSentTime && currentAutoHire?.isActive)
      return AutoHireStatus.NOW_CHOOSING;
    if (historyEntry.notificationSentTime) return AutoHireStatus.NOTIFIED;
    return currentAutoHire?.isActive ? AutoHireStatus.TO_BE_NOTIFIED : AutoHireStatus.SKIPPED;
  };

  const getTimeElapsed = (historyEntry: AutoHireHistoryView) => {
    if (historyEntry.notificationSentTime) {
      if (historyEntry.employee.id === currentEmployee?.employee.id && currentAutoHire?.isActive) {
        // Only calculate dynamic time for the current employee
        const end = historyEntry.signupTime ? new Date(historyEntry.signupTime) : currentTime;
        const duration = intervalToDuration({ start: new Date(historyEntry.notificationSentTime), end });
        const minutes = duration.minutes?.toString().padStart(2, '0') || '00';
        const seconds = duration.seconds?.toString().padStart(2, '0') || '00';
        return `${minutes}:${seconds}`;
      } else {
        const end = historyEntry.signupTime ? new Date(historyEntry.signupTime) : new Date(historyEntry.notificationSentTime);
        const duration = intervalToDuration({ start: new Date(historyEntry.notificationSentTime), end });
        const minutes = duration.minutes?.toString().padStart(2, '0') || '00';
        const seconds = duration.seconds?.toString().padStart(2, '0') || '00';
        return `${minutes}:${seconds}`;
      }
    }
    return historyEntry.isSkipped ? 'Skipped' : 'N/A';
  };

  const tableContainerRef = useRef<HTMLDivElement>(null);

  const handleShowEmployee = (employeeId: number) => {
    if (tableContainerRef.current) {
      const rowElement = tableContainerRef.current.querySelector(`[data-employee-id="${employeeId}"]`);
      if (rowElement) {
        rowElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  };
  const tableData = filteredEmployees.map((historyEntry, index) => ({
    no: index + 1,
    name: (
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, typography: 'bodySRegular' }}>{historyEntry.employee.name}</Box>
    ),
    status: getStatusChip(getStatus(historyEntry), currentAutoHire?.isActive || false),
    timeElapsed: historyEntry.isSkipped ? (
      <Box sx={{ color: theme.palette.stationGray[400], typography: 'bodySRegular' }}> Skipped </Box>
    ) : (
      <Box sx={{ color: theme.palette.stationGray[600], typography: 'bodySRegular' }}>
        {historyEntry.employee.id === currentEmployee?.employee.id && currentAutoHire?.isActive ? (
          <DynamicTimeDisplay getTime={() => getTimeElapsed(historyEntry)} />
        ) : (
          getTimeElapsed(historyEntry)
        )}
      </Box>
    ),
    rank: (
      <Box
        sx={{
          color: theme.palette.stationGray[600],
          typography: 'bodySRegular',
        }}
      >
        {historyEntry.employee.rank.code}
      </Box>
    ),
    battalion: (
      <Box
        sx={{
          color: historyEntry.position ? theme.palette.stationGray[600] : theme.palette.stationGray[400],
          typography: 'bodySRegular',
        }}
      >
        {historyEntry.position?.battalion || 'N/A'}
      </Box>
    ),
    station: (
      <Box
        sx={{
          color: historyEntry.position ? theme.palette.stationGray[600] : theme.palette.stationGray[400],
          typography: 'bodySRegular',
        }}
      >
        {historyEntry.position?.station || 'N/A'}
      </Box>
    ),
    apparatus: (
      <Box
        sx={{
          color: historyEntry.position ? theme.palette.stationGray[600] : theme.palette.stationGray[400],
          typography: 'bodySRegular',
        }}
      >
        {' '}
        {historyEntry.position?.apparatus || 'N/A'}{' '}
      </Box>
    ),
  }));

  const handleEndAutoHire = () => {
    try {
      client
        .post('/auto-hire/end-auto-hire/', {
          auto_hire_id: currentAutoHire?.id,
        })
        .then(() => {
          onClose();
          forceRefetch();
          SnackbarService.notify({
            content: `Auto Hire stopped successfully`,
            severity: 'success',
            duration: 5000,
          });
        });
    } catch (error) {
      captureException(error);
      onClose();
      SnackbarService.notify({
        content: `Failed to stop Auto Hire. Please try again.`,
        severity: 'error',
        duration: 5000,
      });
    }
  };

  const autoHireInQueue = useMemo(() => {
    return !currentAutoHire?.history.some((entry) => entry.notificationSentTime) && currentAutoHire?.isActive;
  }, [currentAutoHire]);

  const autoHireDuration = useMemo(() => {
    const diff = currentTime.getTime() - new Date(currentAutoHire?.createdAt || '').getTime();
    const hours = Math.floor(diff / 3600000);
    const minutes = Math.floor((diff % 3600000) / 60000);
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
  }, [currentTime, currentAutoHire]);

  const formatRemainingTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  const getRemainingTime = (historyEntry: AutoHireHistoryView) => {
    if (historyEntry.notificationSentTime) {
      const end = historyEntry.signupTime ? new Date(historyEntry.signupTime) : currentTime;
      const duration = intervalToDuration({ start: new Date(historyEntry.notificationSentTime), end });
      const elapsedSeconds = (duration.minutes || 0) * 60 + (duration.seconds || 0);
      const remainingTimeInSeconds = Math.max(0, autoHireInfo.autoHireNotificationInterval * 60 - elapsedSeconds);
      return formatRemainingTime(remainingTimeInSeconds);
    }
    return formatRemainingTime(autoHireInfo.autoHireNotificationInterval * 60);
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '90%',
          maxWidth: 1200,
          bgcolor: 'background.paper',
          boxShadow: 24,
          borderRadius: theme.spacing(2),
          maxHeight: '90vh',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.stationGray[500],
          }}
        >
          <CloseIcon />
        </IconButton>

        <Box sx={{ p: 3 }}>
          <Box
            sx={{
              typography: 'bodyXLSemibold',
              color: theme.palette.stationGray[900],
              display: 'flex',
              flexDirection: 'row',
              gap: theme.spacing(1),
              alignItems: 'center',
            }}
          >
            Auto Hire
            <Typography sx={{ typography: 'bodyM', color: theme.palette.stationGray[700] }}>
              {currentAutoHire?.shiftDate
                ? parseISO(currentAutoHire.shiftDate).toLocaleDateString('en-US', {
                    month: 'long',
                    day: 'numeric',
                    year: 'numeric',
                  })
                : 'N/A'}
            </Typography>
          </Box>
          <Box>
            <Box
              sx={{
                display: 'flex',
                borderRadius: theme.spacing(1.5),
                backgroundColor: theme.palette.stationGray[50],
                border: `1px solid ${theme.palette.stationGray[100]}`,
                flexDirection: 'column',
                padding: theme.spacing(2),
                alignSelf: 'stretch',
                alignItems: 'flex-start',
              }}
            >
              <Typography variant="h6" gutterBottom>
                Auction Overview
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                  flexDirection: 'row',
                  borderRadius: theme.spacing(2.5),
                  backgroundColor: theme.palette.stationGray[50],
                  alignSelf: 'stretch',
                  alignItems: 'flex-start',
                  gap: theme.spacing(2),
                  border: `1px solid ${theme.palette.stationGray[100]}`,
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'column',
                    width: theme.spacing(52),
                    height: theme.spacing(16.5),
                    borderRadius: theme.spacing(1.5),
                    backgroundColor: theme.palette.common.white,
                    border: `1px solid ${theme.palette.stationGray[100]}`,
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'space-between',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      marginY: theme.spacing(2),
                      marginX: theme.spacing(2),
                    }}
                  >
                    <Typography variant="bodyXXLMedium" color={theme.palette.stationGray[900]}>
                      Auto Hire
                    </Typography>
                    <Box
                      sx={{
                        display: 'inline-flex',
                        padding: theme.spacing(0.5, 1.5),
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: theme.spacing(1),
                        borderRadius: theme.spacing(4),
                        backgroundColor: currentAutoHire?.isActive
                          ? theme.palette.stationGreen[100]
                          : theme.palette.stationGray[100],
                        color: currentAutoHire?.isActive ? theme.palette.stationGreen[600] : theme.palette.stationGray[600],
                        typography: 'bodySMedium',
                        cursor: 'pointer',
                      }}
                    >
                      {autoHireInQueue ? 'In Queue' : currentAutoHire?.isActive ? 'Active' : 'Inactive'}
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'space-between',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      marginY: theme.spacing(2),
                      marginX: theme.spacing(2),
                    }}
                  >
                    {currentAutoHire?.isActive && (
                      <Button
                        sx={{
                          typography: 'buttonM',
                          textTransform: 'none',
                          borderColor: theme.palette.stationGray[300],
                          color: theme.palette.stationGray[700],
                          border: '1px solid',
                          padding: '9px 13px',
                          height: theme.spacing(4),
                          borderRadius: '6px',
                          backgroundColor: theme.palette.common.white,
                          '&:hover': {
                            borderColor: theme.palette.stationGray[400],
                          },
                        }}
                        onClick={handleEndAutoHire}
                      >
                        Stop Auto Hire
                      </Button>
                    )}

                    <Box
                      sx={{
                        mr: 1,
                        typography: 'bodyM',
                        color: theme.palette.stationGray[600],
                        display: 'flex',
                        alignItems: 'center',
                        gap: theme.spacing(1),
                      }}
                    >
                      <ClockIcon16 />
                      {autoHireDuration} h
                    </Box>
                  </Box>
                </Box>
                {currentEmployee && currentAutoHire?.isActive && !currentAutoHire?.allNotificationsSent && (
                  <Box
                    sx={{
                      flex: 1,
                      alignSelf: 'stretch',
                      borderRadius: theme.spacing(1.5),
                      border: `1px solid ${theme.palette.stationGreen[200]}`,
                      background: theme.palette.stationGreen[50],
                      boxShadow: '0px 1px 2px 0px rgba(10, 14, 22, 0.05)',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'inline-flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: theme.spacing(1),
                        p: '2px 12px 2px 8px',
                        borderRadius: theme.spacing(1),
                        border: `1px solid ${theme.palette.stationGray[200]}`,
                        typography: 'bodySMedium',
                        color: theme.palette.stationGray[900],
                        mt: theme.spacing(2),
                        ml: theme.spacing(1),
                      }}
                    >
                      <GreenLiveIcon16 />
                      {currentEmployee.notificationSentTime ? 'Choosing now' : 'Waiting for Auto Hire to start'}
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: theme.spacing(0.5),
                        flexDirection: 'row',
                        marginLeft: theme.spacing(2),
                        marginTop: theme.spacing(2),
                        color: theme.palette.stationGray[900],
                        typography: 'bodyMMedium',
                      }}
                    >
                      <LetterAvatar size="xsmall" firstName={currentEmployee.employee.name} color="purple" />
                      {currentEmployee.employee.name}
                    </Box>

                    <Box
                      sx={{
                        mt: theme.spacing(1),
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        marginLeft: theme.spacing(2),
                      }}
                    >
                      <Box sx={{ typography: 'bodyMMedium', color: theme.palette.stationGray[900] }}>
                        {currentEmployee.notificationSentTime ? (
                          <>
                            {getRemainingTime(currentEmployee)}
                            <Box component="span" sx={{ color: theme.palette.stationGray[400] }}>
                              {` of ${formatRemainingTime(autoHireInfo.autoHireNotificationInterval * 60)} remaining`}
                            </Box>
                          </>
                        ) : (
                          'To be notified'
                        )}
                      </Box>
                      <Button
                        sx={{
                          typography: 'buttonM',
                          textTransform: 'none',
                          borderColor: theme.palette.stationGray[300],
                          color: theme.palette.stationGray[700],
                          border: '1px solid',
                          marginRight: theme.spacing(2),
                          padding: '9px 13px',
                          height: theme.spacing(4),
                          borderRadius: '6px',
                          backgroundColor: theme.palette.common.white,
                        }}
                        onClick={() => currentEmployee && handleShowEmployee(Number(currentEmployee.employee.id))}
                      >
                        Show
                      </Button>
                    </Box>
                  </Box>
                )}
                {nextEmployee && currentAutoHire?.isActive && currentEmployee && (
                  <Box
                    sx={{
                      flex: 1,
                      alignSelf: 'stretch',
                      borderRadius: theme.spacing(1.5),
                      border: `1px solid ${theme.palette.stationGray[200]}`,
                      background: theme.palette.common.white,
                      boxShadow: '0px 1px 2px 0px rgba(10, 14, 22, 0.05)',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'inline-flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: theme.spacing(1),
                        p: '2px 12px 2px 8px',
                        borderRadius: theme.spacing(1),
                        border: `1px solid ${theme.palette.stationGray[200]}`,
                        typography: 'bodySMedium',
                        color: theme.palette.stationGray[900],
                        mt: theme.spacing(2),
                        ml: theme.spacing(1),
                      }}
                    >
                      Next
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: theme.spacing(0.5),
                        flexDirection: 'row',
                        marginLeft: theme.spacing(2),
                        marginTop: theme.spacing(2),
                        color: theme.palette.stationGray[900],
                        typography: 'bodyMMedium',
                      }}
                    >
                      <LetterAvatar size="xsmall" firstName={nextEmployee.employee.name} color="orange" />
                      {nextEmployee.employee.name}
                    </Box>

                    <Box
                      sx={{
                        mt: theme.spacing(1),
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        marginLeft: theme.spacing(2),
                      }}
                    >
                      <Box sx={{ typography: 'bodyMMedium', color: theme.palette.stationGray[400] }}>
                        {`Starts in ${getRemainingTime(currentEmployee)}`}
                      </Box>

                      <Button
                        sx={{
                          typography: 'buttonM',
                          textTransform: 'none',
                          borderColor: theme.palette.stationGray[300],
                          color: theme.palette.stationGray[700],
                          border: '1px solid',
                          marginRight: theme.spacing(2),
                          padding: '9px 13px',
                          height: theme.spacing(4),
                          borderRadius: '6px',
                          backgroundColor: theme.palette.common.white,
                        }}
                        onClick={() => nextEmployee && handleShowEmployee(Number(nextEmployee.employee.id))}
                      >
                        Show
                      </Button>
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
          <TextField
            sx={{ mb: 3, mt: 3 }}
            fullWidth
            placeholder="Search for people in your team"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </Box>

        <Box ref={tableContainerRef} sx={{ flexGrow: 1, overflow: 'auto', px: 3, pb: 3, height: '1600px' }}>
          <CustomTable
            columns={columns}
            data={tableData}
            getRowProps={(row, index) => ({
              'data-employee-id': filteredEmployees[index].employee.id.toString(),
            })}
          />
        </Box>
      </Box>
    </Modal>
  );
};
