import { Box } from '@mui/material';
import { captureException } from '@sentry/react';
import { useDeferredValue, useEffect, useRef, useState } from 'react';
import {
  RankBadge,
  CertBadge,
  CustomTable,
  TeamBadge,
  LottieLogo,
  SearchAndFilter,
  TableProps,
} from '@stationwise/component-module';
import { client, isAxiosError } from '@stationwise/share-api';
import { EligibleEmployeeForHiringEngine } from '@stationwise/share-types';
import { camelCaseToTitle, capitalize } from '@stationwise/share-utils';

interface FilterOptions {
  [key: string]: string[];
}
interface EligibleEmployeesContentProps {
  shiftDate: string;
}

export const EligibleEmployeesContent = ({ shiftDate }: EligibleEmployeesContentProps) => {
  const [eligibleEmployees, setEligibleEmployees] = useState<EligibleEmployeeForHiringEngine[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const abortControllerRef = useRef<AbortController | null>(null);
  const [searchInput, setSearchInput] = useState('');
  const deferredSearchInput = useDeferredValue(searchInput);
  const [selectedFilters, setSelectedFilters] = useState<Record<string, string>>({});
  const [filterOptions, setFilterOptions] = useState<FilterOptions>({});

  useEffect(() => {
    const fetchFilterOptions = async () => {
      try {
        const response = await client.get('employee/request-volunteers/filter_options/', {
          params: { shiftDate: shiftDate },
        });
        if (response.data) {
          setFilterOptions(response.data);
        }
      } catch (error) {
        captureException(error);
      }
    };
    fetchFilterOptions();
  }, [shiftDate]);

  useEffect(() => {
    const getEligibleEmployees = async () => {
      setIsLoading(true);
      abortControllerRef.current?.abort();
      abortControllerRef.current = new AbortController();

      try {
        const filterParams = Object.fromEntries(Object.entries(selectedFilters).filter(([_, value]) => value));
        const response = await client.get('employee/request-volunteers/', {
          params: {
            shiftDate: shiftDate,
            ...(deferredSearchInput && { search: deferredSearchInput }),
            ...filterParams,
            signal: abortControllerRef.current.signal,
          },
        });
        const allEmployees: EligibleEmployeeForHiringEngine[] = [];
        response.data.forEach((employee: EligibleEmployeeForHiringEngine) => {
          allEmployees.push({
            id: employee.id,
            rank: employee.rank,
            name: employee.name,
            certifications: employee.certifications,
            plannedShiftDetails: employee.plannedShiftDetails,
          });
        });

        setIsLoading(false);
        setEligibleEmployees(allEmployees);
      } catch (error) {
        const isCanceled = isAxiosError(error) && error.code === 'ERR_CANCELED';
        !isCanceled && captureException(error);
        !isCanceled && setIsLoading(false);
      }
    };
    getEligibleEmployees();
  }, [shiftDate, deferredSearchInput, selectedFilters]);

  const handleFilterChange = (filterName: string, value: string) => {
    setSelectedFilters((prev) => ({ ...prev, [filterName]: value }));
  };

  const handleSearchChange = (value: string) => {
    setSearchInput(value);
  };

  const filters = Object.entries(filterOptions).map(([filterName, options]) => ({
    name: camelCaseToTitle(capitalize(filterName)),
    options: options.map((option) => ({
      label: capitalize(option),
      value: capitalize(option),
    })),
    selected: selectedFilters[filterName],
    onChange: (value: string) => handleFilterChange(filterName, value),
  }));

  const clearAllFilters = () => {
    setSelectedFilters({});
  };
  const columns: TableProps['columns'] = [
    { id: 'name', label: 'Name', minWidth: 200, align: 'left' },
    { id: 'rank', label: 'Rank', minWidth: 64, align: 'center' },
    { id: 'certificates', label: 'Certificates', minWidth: 150, align: 'left' },
    { id: 'shift', label: 'Shift', minWidth: 150, align: 'left' },
  ];

  const data: TableProps['data'] = eligibleEmployees.map((employee) => ({
    name: (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          typography: 'bodySMedium',
        }}
      >
        {employee.name}
      </Box>
    ),
    rank: <RankBadge rank={employee.rank} />,
    certificates: (
      <Box
        sx={(theme) => ({
          display: 'flex',
          gap: theme.spacing(1),
          typography: 'bodySMedium',
        })}
      >
        {employee.certifications.map((cert) => (
          <CertBadge key={cert.id} cert={cert} />
        ))}
      </Box>
    ),
    shift:
      employee.plannedShiftDetails && employee.plannedShiftDetails.length > 0 ? (
        <TeamBadge
          team={{
            name: employee.plannedShiftDetails[0].teamName,
            color: employee.plannedShiftDetails[0].shiftColor,
          }}
        />
      ) : (
        <TeamBadge team={{ name: 'Unassigned', color: 'gray' }} />
      ),
  }));
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '828px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignContent: 'center',
          typography: 'bodySMedium',
          alignItems: 'center',
        }}
      >
        <SearchAndFilter
          searchText={searchInput}
          setSearchText={handleSearchChange}
          filters={filters}
          clearAllFilters={clearAllFilters}
          searchPlaceholder="Search for people in your team"
        />
      </Box>
      <Box>
        {isLoading && (
          <Box display="flex" justifyContent="center" sx={{ height: '100%', width: '100%' }}>
            <LottieLogo width={'150px'} height={'150px'} />
          </Box>
        )}
        {!isLoading && <CustomTable columns={columns} data={data} />}
      </Box>
    </Box>
  );
};
