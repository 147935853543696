import { captureException } from '@sentry/react';
import { SnackbarService, SwDialog } from '@stationwise/component-module';
import { client } from '@stationwise/share-api';
import { EligibleEmployeesContent } from './EligibleEmployeesContent';

interface EligibleEmployeesMainProps {
  selectedDate: string;
  showEligibleEmployees: boolean;
  setShowEligibleEmployees: (show: boolean) => void;
  bulkCallIsLoading: boolean;
  setBulkCallIsLoading: (loading: boolean) => void;
  forceRefetch: () => void;
  setBulkCallIsOpen: (open: boolean) => void;
}

export const EligibleEmployeesMain = ({
  selectedDate,
  showEligibleEmployees,
  setShowEligibleEmployees,
  bulkCallIsLoading,
  setBulkCallIsLoading,
  forceRefetch,
  setBulkCallIsOpen,
}: EligibleEmployeesMainProps) => {
  const bulkCall = async () => {
    try {
      setBulkCallIsLoading(true);
      const response = await client.post(`/shift/bulk-call/`, {
        shiftDate: selectedDate,
      });
      SnackbarService.notify({
        content: response.data.message,
        severity: 'success',
        duration: 5000,
      });
      forceRefetch();
      setBulkCallIsLoading(false);
      setBulkCallIsOpen(false);
      setShowEligibleEmployees(false);
    } catch (error) {
      SnackbarService.notify({
        content: 'Error sending bulk call',
        severity: 'error',
      });
      captureException(error);
      setBulkCallIsLoading(false);
      setBulkCallIsOpen(false);
      setShowEligibleEmployees(false);
    }
  };

  return (
    <SwDialog
      isOpen={showEligibleEmployees}
      onSubmit={bulkCall}
      onCancel={() => setShowEligibleEmployees(false)}
      title="Request volunteers"
      label="Send Volunteer Request"
      loading={bulkCallIsLoading}
      width="660px"
    >
      <EligibleEmployeesContent shiftDate={selectedDate} />
    </SwDialog>
  );
};
