import { Button, Box, Typography, Menu, MenuItem } from '@mui/material';
import React from 'react';
import { ListFieldsStaffingList } from '@stationwise/share-types';
import { ChevronDownIcon20 } from '../../../assets';
import { useFetchStaffingLists } from '../../../hooks';

interface ActionButtonProps {
  requestOvertimeDisabled?: boolean;
  handleRequestOvertimeClick?: (staffingList: ListFieldsStaffingList) => void;
  setCreateIncidentOpen?: (value: boolean) => void;
  setCreateIncident: (value: boolean) => void;
}

export const ActionButton = ({
  requestOvertimeDisabled,
  handleRequestOvertimeClick,
  setCreateIncidentOpen,
  setCreateIncident,
}: ActionButtonProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const { isLoading: staffingListsIsLoading, data } = useFetchStaffingLists();
  const staffingLists = data?.map((sl: ListFieldsStaffingList) => sl) || [];
  const disabled = requestOvertimeDisabled || staffingListsIsLoading;

  const filteredStaffingLists = staffingLists.filter((sl) => sl.isOptOut || sl.isSignUp);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    if (filteredStaffingLists.length > 1) {
      setAnchorEl(event.currentTarget);
    } else if (filteredStaffingLists.length === 1) {
      handleRequestOvertimeClick?.(filteredStaffingLists[0]);
    }
  };
  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <Box>
      <Button
        data-cy="request-overtime-button"
        sx={(theme) => ({
          display: 'flex',
          alignItems: 'center',
          height: '40px',
          padding: '9px 17px 9px 17px',
          backgroundColor: theme.palette.stationPrimary[400],
          color: theme.palette.common.black,
          borderRadius: 2,
          '&: hover': {
            backgroundColor: theme.palette.stationPrimary[400],
          },
        })}
        disabled={disabled}
        onClick={handleClick}
      >
        <Typography variant="buttonM">
          {filteredStaffingLists.length === 1
            ? `${filteredStaffingLists[0].isOptOut ? 'Opt out from ' : 'Sign up for '}${filteredStaffingLists[0].name}`
            : 'Sign up'}
        </Typography>
        {filteredStaffingLists.length > 1 && (
          <>
            <Box
              sx={(theme) => ({
                ml: theme.spacing(2),
                display: 'flex',
                alignItems: 'center',
                textAlign: 'center',
                borderLeft: `1px solid ${theme.palette.common.white}`,
                pl: theme.spacing(1.5),
                '& svg path': {
                  stroke: requestOvertimeDisabled ? theme.palette.stationGray[300] : theme.palette.common.black,
                },
              })}
              onClick={handleClick}
            >
              <ChevronDownIcon20 />
            </Box>
            <Menu
              id="avatar-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              {filteredStaffingLists.map((staffingList: ListFieldsStaffingList) => (
                <MenuItem
                  key={`Request_${staffingList.id}`}
                  onClick={(event) => {
                    setCreateIncident(false);
                    setCreateIncidentOpen && setCreateIncidentOpen(false);
                    if (handleRequestOvertimeClick) handleRequestOvertimeClick(staffingList);
                    handleClose(event);
                  }}
                  sx={{
                    typography: 'bodySMRegular',
                  }}
                >
                  {`${staffingList.isOptOut ? 'Opt out from ' : 'Sign up for '}${staffingList.name}`}
                </MenuItem>
              ))}
            </Menu>
          </>
        )}
      </Button>
    </Box>
  );
};
