// import { SERVER_BASE_URL } from './services';
import * as Sentry from '@sentry/react';
import { useState } from 'react';
import { LoggedInEmployeeDataView } from '@stationwise/share-types';

export const useSentry = () => {
  const [isInitialized, setIsInitialized] = useState(false);

  if (!isInitialized) {
    init();
    setIsInitialized(true);
  }
};

export function init() {
  const isDevelopment = process.env['NODE_ENV'] === 'development';

  if (!process.env['NX_PUBLIC_SENTRY_DSN'] || isDevelopment) return;

  Sentry.init({
    dsn: process.env['NX_PUBLIC_SENTRY_DSN'],
    integrations: [Sentry.replayIntegration()],
    environment: process.env['NX_PUBLIC_SW_ENV'],

    /* Performance Monitoring */
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!

    /* Tunneling */
    tunnel: '/sentry-tunnel/',

    /* Session Replay */
    replaysSessionSampleRate: 0.01, // This sets the sample rate at 1%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.

    ignoreErrors: [
      // This happens when the user's network connection is inconsistent.
      'Network Error',
      // This happens when the browser is reloaded while an API call is in progress.
      'Request aborted',
      // Following two occur when Outlook scans links for safety
      'Non-Error exception captured',
      'Non-Error promise rejection captured',
    ],
  });
}

export function setSentryUser(employee: LoggedInEmployeeDataView) {
  Sentry.setUser({
    id: employee.id,
    email: employee.email,
    department: employee.departmentDomain,
  });
}
