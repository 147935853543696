import { EligibleEmployeeForHiringEngine, StaffingListItem } from '@stationwise/share-types';
import { getMissingCertifications } from '@stationwise/shift-summary-helper';
import { HiringEngineVacancy } from '../Vacancies/vacanciesHelper';

interface MatchParams {
  staffingListItems: StaffingListItem[];
  vacancies: HiringEngineVacancy[];
  searchInput: string;
  selectedTeamOptions: Set<string>;
  selectedBattalionOptions: Set<string>;
}

export interface VacancyEligibilityDetailed {
  vacancyIndex: number;
  vacancy: HiringEngineVacancy;
  eligibleEmployees: EligibleEmployeeForHiringEngine[];
}

export function matchEmployeesToVacancies({
  staffingListItems,
  vacancies,
  searchInput,
  selectedTeamOptions,
  selectedBattalionOptions,
}: MatchParams): VacancyEligibilityDetailed[] {
  const filteredStaffingListItems = staffingListItems.filter((item) => {
    if (searchInput && !item.employee.name.toLowerCase().includes(searchInput.toLowerCase())) return false;
    if (selectedTeamOptions.size && !selectedTeamOptions.has(item.employee.team?.name || '')) return false;
    if (selectedBattalionOptions.size && !selectedBattalionOptions.has(item.employee.battalion?.name || '')) return false;
    return true;
  });

  return vacancies.map((vacancy, vIndex) => {
    const eligible = filteredStaffingListItems
      .filter(
        (staffingListItem) =>
          getMissingCertifications(vacancy.certifications, staffingListItem.employee.certifications).length === 0,
      )
      .map((staffingListItem) => ({
        id: staffingListItem.employee.id,
        name: staffingListItem.employee.name,
        rank: staffingListItem.employee.rank,
        certifications: staffingListItem.employee.certifications,
        plannedShiftDetails: [],
      }));
    return {
      vacancyIndex: vIndex,
      vacancy: vacancy,
      eligibleEmployees: eligible,
    };
  });
}
