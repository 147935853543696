import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Dialog, DialogContent, DialogTitle, IconButton, Tooltip, Typography, useTheme } from '@mui/material';
import { format } from 'date-fns';
import { useState, useEffect } from 'react';
import { XCloseIcon24 } from '@stationwise/component-module';
import { InstantHireView } from '@stationwise/share-types';
import { InstantHireHistoryCard } from './InstantHireHistoryCard';
import { InstantHireHistoryDetails } from './InstantHireHistoryDetails';
interface InstantHireHistoryProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  setCreateInstantHireModalOpen: (open: boolean) => void;
  instantHires: InstantHireView[];
  hasVacancies: boolean;
  hasRosterChanges: boolean;
}

export const InstantHireHistory = ({
  instantHires,
  open,
  setOpen,
  setCreateInstantHireModalOpen,
  hasVacancies,
  hasRosterChanges,
}: InstantHireHistoryProps) => {
  const theme = useTheme();
  const [selectedInstantHire, setSelectedInstantHire] = useState<InstantHireView>(instantHires[0]);
  const [openInstantHireHistoryDetails, setOpenInstantHireHistoryDetails] = useState(false);
  const [numberOfAcknowledged, setNumberOfAcknowledged] = useState(0);

  useEffect(() => {
    setNumberOfAcknowledged(
      instantHires.reduce(
        (acc, instantHire) => acc + instantHire.positions.filter((position) => position.isAcknowledged).length,
        0,
      ),
    );
  }, [instantHires]);

  const closeModal = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={closeModal}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <DialogTitle>
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, alignItems: 'center' }}>
          <Box sx={{ typography: 'bodyXLSemibold' }}>Instant Hire History</Box>
          <Typography variant="bodySRegular">{format(selectedInstantHire.shiftDate, 'MMM d, yyyy')}</Typography>
        </Box>
        <IconButton
          aria-label="close"
          onClick={closeModal}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          }}
        >
          <XCloseIcon24 />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography variant="buttonL">Instant Hire History</Typography>
              {hasVacancies && (
                <Tooltip title={hasRosterChanges ? 'You should save your roster changes before starting an instant hire' : ''}>
                  <span>
                    <Button
                      variant="text"
                      color="primary"
                      startIcon={<AddIcon />}
                      onClick={() => setCreateInstantHireModalOpen(true)}
                      disabled={hasRosterChanges}
                    >
                      Start Instant Hire
                    </Button>
                  </span>
                </Tooltip>
              )}
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
              }}
            >
              {instantHires.map((instantHire, index) => (
                <InstantHireHistoryCard
                  key={instantHire.id}
                  index={index + 1}
                  instantHire={instantHire}
                  setSelectedInstantHire={setSelectedInstantHire}
                  setOpenInstantHireHistoryDetails={setOpenInstantHireHistoryDetails}
                  numberOfAcknowledged={numberOfAcknowledged}
                />
              ))}
            </Box>

            <InstantHireHistoryDetails
              instantHire={selectedInstantHire}
              open={openInstantHireHistoryDetails}
              setOpen={setOpenInstantHireHistoryDetails}
              numberOfAcknowledged={numberOfAcknowledged}
            />
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
