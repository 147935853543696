import { Box, Checkbox, Typography } from '@mui/material';
import { useMemo } from 'react';
import { EligibleEmployeeForHiringEngine } from '@stationwise/share-types';
import { HiringEngineVacancy } from '../Vacancies/vacanciesHelper';
import { VacancyEmployeePair } from './VacancyEmployeePair';
import { VacancyEligibilityDetailed } from './matchEmployeesToVacancies';

interface InstantHireCardsProps {
  allVacancies: HiringEngineVacancy[];
  vacancyEligibility: VacancyEligibilityDetailed[];
  selectedVacancies: HiringEngineVacancy[];
  selectedEligibleEmployeeIds: string[];
  selectAll: boolean;
  onToggleVacancy: (vacancy: HiringEngineVacancy) => void;
  onToggleSelectAll: (selectAll: boolean) => void;
}

export const InstantHireCards = ({
  allVacancies,
  vacancyEligibility,
  selectedVacancies,
  selectedEligibleEmployeeIds,
  selectAll,
  onToggleVacancy,
  onToggleSelectAll,
}: InstantHireCardsProps) => {
  const allNullOrEmpty = useMemo(() => vacancyEligibility.every((v) => v.eligibleEmployees.length === 0), [vacancyEligibility]);

  const vacancyIndexToEmployeeId = useMemo(() => {
    const map: Record<number, string | null> = {};
    selectedVacancies.forEach((vacancy, i) => {
      const vIndex = allVacancies.indexOf(vacancy);
      map[vIndex] = selectedEligibleEmployeeIds[i] || null;
    });
    return map;
  }, [selectedVacancies, selectedEligibleEmployeeIds, allVacancies]);

  const employeeMap = useMemo(() => {
    const map: Record<string, EligibleEmployeeForHiringEngine> = {};
    for (const v of vacancyEligibility) {
      for (const emp of v.eligibleEmployees) {
        map[emp.id] = emp;
      }
    }
    return map;
  }, [vacancyEligibility]);

  const orderedVacancies = useMemo(() => {
    return allVacancies.slice().sort((a, b) => {
      const aSelected = selectedVacancies.includes(a);
      const bSelected = selectedVacancies.includes(b);

      const aHasEmployee = vacancyEligibility[allVacancies.indexOf(a)].eligibleEmployees.length > 0;
      const bHasEmployee = vacancyEligibility[allVacancies.indexOf(b)].eligibleEmployees.length > 0;

      if (aSelected && !bSelected) return -1;
      if (!aSelected && bSelected) return 1;
      if (aHasEmployee && !bHasEmployee) return -1;
      if (!aHasEmployee && bHasEmployee) return 1;
      return 0;
    });
  }, [allVacancies, selectedVacancies, vacancyEligibility]);

  return (
    <Box>
      {!allNullOrEmpty && (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
          <Checkbox checked={selectAll} onChange={() => onToggleSelectAll(!selectAll)} />
          <Typography variant="body1">Select all</Typography>
        </Box>
      )}
      {orderedVacancies.map((vacancy, index) => {
        const originalIndex = allVacancies.indexOf(vacancy);
        const isSelected = selectedVacancies.includes(vacancy);
        const assignedEmployeeId = vacancyIndexToEmployeeId[originalIndex];
        const assignedEmployee = assignedEmployeeId ? employeeMap[assignedEmployeeId] : undefined;
        const isDisabled = vacancyEligibility[originalIndex].eligibleEmployees.length === 0;
        const diffInMinutes = (vacancy.endDateTime.getTime() - vacancy.startDateTime.getTime()) / 60000;

        return (
          <VacancyEmployeePair
            key={index}
            vacancy={vacancy}
            employee={assignedEmployee}
            isSelected={isSelected}
            isDisabled={isDisabled}
            onToggle={() => onToggleVacancy(vacancy)}
            durationMinutes={diffInMinutes}
          />
        );
      })}
    </Box>
  );
};
