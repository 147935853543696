import { Box, Tooltip } from '@mui/material';
import { captureException } from '@sentry/react';
import { useEffect, useState } from 'react';
import {
  ArrowRightIcon20,
  Loader,
  PeopleLogo,
  SelectWorkgroupLogo,
  SnackbarService,
  useAuthUserCapabilities,
} from '@stationwise/component-module';
import { axios, client } from '@stationwise/share-api';
import { WorkGroupMember, WorkGroupPreview } from '@stationwise/share-types';
import { RemoveWorkGroupModal } from './RemoveWorkGroupModal';
import { TableMembers } from './TableMembers';

interface ListMembersProps {
  workGroup: WorkGroupPreview | undefined;
  updateMemberCount: (id: number, removedUserCount: number, members: WorkGroupMember[]) => void;
}

export const ListMembers = ({ workGroup, updateMemberCount }: ListMembersProps) => {
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [employeeMap, setEmployeeMap] = useState<{ [id: number]: WorkGroupMember[] }>({});
  const capabilities = useAuthUserCapabilities();
  const canManageWorkGroups = capabilities.MANAGE_WORK_GROUPS;

  useEffect(() => {
    setSelectedIds([]);
  }, [workGroup]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!workGroup) return;
        if (employeeMap[workGroup.id]) return;
        setLoading(true);
        const response = await client.get(`organization/workgroups-members/${workGroup.id}/employees-by-workgroup/`, {});
        setEmployeeMap((prevMap) => ({
          ...prevMap,
          [workGroup.id]: response.data,
        }));
      } catch (error) {
        captureException(error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [workGroup, employeeMap]);

  const handleSelect = (id: string) => {
    if (!workGroup) return;
    if (!employeeMap[workGroup.id]) return;
    if (id === '-1') {
      if (selectedIds.length === employeeMap[workGroup.id].length) {
        setSelectedIds([]);
      } else {
        setSelectedIds(employeeMap[workGroup.id].map((emp) => emp.id));
      }
    } else {
      setSelectedIds((prev) => (prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]));
    }
  };

  const updateMembers = (employees: WorkGroupMember[]) => {
    if (!workGroup) return;
    setEmployeeMap((prevMap) => {
      return {
        ...prevMap,
        [workGroup.id]: employees,
      };
    });
  };

  const removeFromWorkGroup = async () => {
    try {
      if (!selectedIds || !workGroup) return;
      setLoading(true);
      const response = await client.post(`organization/workgroups-members/${workGroup.id}/remove-employees-from-group/`, {
        employeeIds: selectedIds,
      });
      updateMembers(response.data);
      updateMemberCount(workGroup.id, selectedIds.length, response.data);
      SnackbarService.notify({
        content: `${selectedIds.length} users removed from ${workGroup.name} work group`,
        severity: 'success',
        duration: 5000,
      });
    } catch (error) {
      const message = axios.isAxiosError(error)
        ? error.response?.data?.message || error.message
        : 'An unexpected error occurred.';
      SnackbarService.notify({
        content: message,
        severity: 'error',
        duration: 5000,
      });
      captureException(error);
    } finally {
      setLoading(false);
    }
  };

  const handleRemove = () => {
    removeFromWorkGroup();
    setShowModal(false);
  };

  const header = `Do you really wish to remove people from ${workGroup?.name} work group?`;

  if (loading) {
    return (
      <Box
        sx={(theme) => ({
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '50%',
        })}
      >
        <Loader />
      </Box>
    );
  } else if (workGroup === undefined) {
    return (
      <Box
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '50%',
          width: '100%',
        })}
      >
        <Box
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            p: theme.spacing(3),
            mt: theme.spacing(3),
            textAlign: 'center',
            justifyContent: 'center',
            height: '100%',
            width: '372px',
          })}
        >
          <SelectWorkgroupLogo />
          <Box sx={{ typography: 'heading4' }}>Select a Work Group to view or edit its members</Box>
        </Box>
      </Box>
    );
  } else if (workGroup.membersCount === 0 || !employeeMap[workGroup.id]) {
    return (
      <Box
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '50%',
          width: '100%',
        })}
      >
        <Box
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            p: theme.spacing(3),
            mt: theme.spacing(3),
            textAlign: 'center',
            justifyContent: 'center',
            height: '100%',
          })}
        >
          <PeopleLogo />
          <Box sx={(theme) => ({ my: theme.spacing(2), typography: 'heading4' })}>Add people to this group</Box>
          <Box sx={(theme) => ({ my: theme.spacing(2), typography: 'bodyMRegular' })}>
            This group has no members yet. Select people to add in your My team page
          </Box>
          <Box
            sx={(theme) => ({
              mt: theme.spacing(2),
              p: '9px 15px 9px 17px',
              borderRadius: 1.5,
              display: 'flex',
              alignItems: 'center',
              typography: 'buttonL',
              border: `1px solid ${theme.palette.stationGray[300]}`,
              '&: hover': {
                cursor: 'pointer',
              },
            })}
            onClick={() => window.location.reload()}
          >
            Go to my team
            <Box sx={(theme) => ({ color: theme.palette.stationGray[500], display: 'flex', marginLeft: theme.spacing(2) })}>
              <ArrowRightIcon20 />
            </Box>
          </Box>
        </Box>
      </Box>
    );
  } else if (employeeMap[workGroup.id]) {
    return (
      <>
        <RemoveWorkGroupModal handleRemove={handleRemove} showModal={showModal} setShowModal={setShowModal} header={header} />
        {selectedIds.length > 0 && canManageWorkGroups && (
          <Tooltip title="" placement="top">
            <Box
              sx={(theme) => ({
                position: 'fixed',
                bottom: '20px',
                padding: '12px 16px 12px 16px',
                backgroundColor: theme.palette.stationGray[800],
                color: theme.palette.stationGray[50],
                borderRadius: 3,
                zIndex: theme.zIndex.tooltip,
                typography: 'bodySMedium',
                '&: hover': {
                  cursor: 'pointer',
                },
              })}
              onClick={() => setShowModal(true)}
            >
              <Box
                sx={(theme) => ({
                  borderRadius: 2,
                  padding: '6px 8px',
                  backgroundColor: theme.palette.stationGray[700],
                })}
              >
                Remove from work group
              </Box>
            </Box>
          </Tooltip>
        )}
        <TableMembers
          selectedIds={selectedIds}
          handleClick={handleSelect}
          employees={employeeMap[workGroup.id]}
          hideCheckbox={!canManageWorkGroups}
        />
      </>
    );
  } else return null;
};
