import { Box } from '@mui/material';
import { captureException } from '@sentry/react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDocumentTitle } from '@stationwise/component-module';
import { client, isAxiosError } from '@stationwise/share-api';
import { EmployeeProfileDetailsView } from '@stationwise/share-types';
import { AccountControlSection } from './AccountControlSection';
import { AccrualsSection } from './AccrualsSection';
import { CareerSection } from './CareerSection';
import { DefaultStaffingSection } from './DefaultStaffingSection';
import { PatchRequestProvider } from './Edit/PatchRequestProvider';
import { EmployeeInfoSection } from './EmployeeInfoSection';
import { GroupsSection } from './GroupsSection';
import { ManagersSection } from './ManagersSection';
import { QualificationsSection } from './QualificationsSection';

type EmployeeProfileContentProps = {
  employeeId?: string;
};

export const EmployeeProfileContent = ({ employeeId }: EmployeeProfileContentProps) => {
  const navigate = useNavigate();
  const [employee, setEmployee] = useState<EmployeeProfileDetailsView | null>(null);
  const [refresh, setRefresh] = useState<boolean>(false);
  const url = `/employee/team/${employeeId}/`;
  const employeeName = `${employee?.firstName} ${employee?.lastName}`;
  useDocumentTitle(`${employeeName ? `${employeeName} - ` : ''}Employee Details`);

  useEffect(() => {
    const getEmployee = async () => {
      try {
        const response = await client.get(`/employee/team/${employeeId}/`);
        setEmployee(response.data);
      } catch (error) {
        if (isAxiosError(error) && error.response?.status === 404) {
          navigate('/app/employees');
        } else {
          captureException(error);
        }
      }
    };

    getEmployee();
  }, [employeeId, refresh, navigate]);

  if (!employee) {
    return null;
  }
  const handleRefresh = () => {
    if (setRefresh) {
      setRefresh(!refresh);
    }
  };
  return (
    <PatchRequestProvider url={url} setRefresh={setRefresh} name={employeeName}>
      <Box sx={{ width: '75%', mx: 'auto' }}>
        <Box sx={{ display: 'flex', gap: 7, pb: 2 }}>
          <Box sx={{ width: 396, maxWidth: '45%' }}>
            <AccrualsSection
              accruals={employee.accruals}
              role={employee.role}
              firstName={employee.firstName}
              lastName={employee.lastName}
              id={employee.id}
              isActive={employee.isActive}
              handleRefresh={handleRefresh}
            />
          </Box>
          <Box
            sx={(theme) => ({
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: 2,
            })}
          >
            <EmployeeInfoSection
              phone={employee.phone}
              email={employee.email}
              payrollId={employee.payrollId}
              emergencyContactName={employee.emergencyContactName}
              emergencyContactPhone={employee.emergencyContactPhone}
              emergencyContactRelationship={employee.emergencyContactRelationship}
              optionalEmergencyContactName={employee.optionalEmergencyContactName}
              optionalEmergencyContactPhone={employee.optionalEmergencyContactPhone}
              optionalEmergencyContactRelationship={employee.optionalEmergencyContactRelationship}
            />
            <QualificationsSection
              certificationActAsRank={employee.certificationActAsRank}
              certifications={employee.certifications}
              rank={employee.rank}
              driversLicenseNumber={employee.driversLicenseNumber}
              driversLicenseClasses={employee.driversLicenseClasses}
            />
            <DefaultStaffingSection
              plannedShiftDetails={employee.plannedShiftDetails ?? []}
              rankName={employee.rank.name}
              nonShiftDetail={employee.nonShiftDetail}
              employeeDefaults={employee.defaults}
            />
            <CareerSection hireDate={employee.hireDate} promotionHistory={employee.promotionHistory} />
            <GroupsSection workGroups={employee.workGroups} />
            <ManagersSection
              managerOne={employee.managerOne}
              managerTwo={employee.managerTwo}
              managerThree={employee.managerThree}
            />
            <AccountControlSection
              isActive={employee.isActive}
              canDeactivate={employee.canDeactivate}
              lastPasswordChange={employee.lastPasswordChange}
              employeeId={employee.id}
              handleRefresh={handleRefresh}
            />
          </Box>
        </Box>
      </Box>
    </PatchRequestProvider>
  );
};
