import { captureException } from '@sentry/react';
import Pusher from 'pusher-js';
import { useEffect, useState } from 'react';
import { client, isAxiosError } from '@stationwise/share-api';
import { AbstractChannel, IntervalChannel, PUSHER_EVENT_TYPES, PusherChannelAdapter } from '@stationwise/share-utils';
import { checkVersion } from '../../../utils';
import { ErrorPage } from '../../ErrorPage';
import { LottieLogoPageLoader } from '../../LottieLogo';
import { useDepartmentInfoContext } from '../context/DepartmentInfo';

interface DepartmentInfoLoaderProps {
  children: JSX.Element;
  redirectTo: string;
}

export const DepartmentInfoLoader = ({ children, redirectTo }: DepartmentInfoLoaderProps) => {
  const { state, dispatch } = useDepartmentInfoContext();
  const isLoaded = !!state.departmentInfo;
  const [isLoading, setIsLoading] = useState(!isLoaded);

  useEffect(() => {
    if (isLoaded && !state.refetchData) {
      return;
    }

    const loadDepartmentInfo = async () => {
      try {
        const [myDepartmentResponse, featureFlagsResponse] = await Promise.all([
          client.get('/organization/my-department/'),
          client.get('/customization/feature-flags/'),
        ]);
        const departmentInfo = {
          ...myDepartmentResponse.data,
          featureFlags: featureFlagsResponse.data,
        };
        dispatch({ type: 'SET_DEPARTMENT_INFO.SUCCESS', payload: departmentInfo });
        setIsLoading(false);
      } catch (error) {
        const status = isAxiosError(error) && error.response?.status;
        if (status === 401) {
          // Not using router because we want to reload the page.
          window.location.replace(redirectTo);
        } else {
          captureException(error);
          setIsLoading(false);
        }
      }
    };

    loadDepartmentInfo();
  }, [isLoaded, dispatch, redirectTo, state.refetchData]);

  useEffect(() => {
    if (!state.departmentInfo?.id) {
      return;
    }

    let refreshTriggerChannel: AbstractChannel | null;

    const fallbackToIntervalChannel = () => {
      if (state.departmentInfo?.settings?.isPullEnabled) {
        refreshTriggerChannel = new IntervalChannel();
        refreshTriggerChannel.bind_many([PUSHER_EVENT_TYPES.RELOAD], checkVersion);
        dispatch({ type: 'SET_PUSHER_CHANNEL', payload: refreshTriggerChannel });
      }
    };

    if (state.departmentInfo.settings?.isPusherEnabled) {
      const pusher = new Pusher(process.env['NX_PUBLIC_PUSHER_KEY'] || '', {
        cluster: process.env['NX_PUBLIC_PUSHER_CLUSTER'] || 'us3',
      });
      const channel = pusher.subscribe(`department-${state.departmentInfo.id}-channel`);

      pusher.connection.bind('connected', () => {
        refreshTriggerChannel = new PusherChannelAdapter(channel);
        refreshTriggerChannel.bind_many([PUSHER_EVENT_TYPES.RELOAD], checkVersion);
        dispatch({ type: 'SET_PUSHER_CHANNEL', payload: refreshTriggerChannel });
      });

      pusher.connection.bind('error', (error: { type: string; error: Error }) => {
        // Silenced until we can figure out why this is happening.
        // captureException(new Error(`${error.type}`),);
        pusher.disconnect();
        fallbackToIntervalChannel();
      });

      channel.bind('pusher:subscription_error', (status: { status: number }) => {
        // Silenced until we can figure out why this is happening.
        // captureException(new Error(`Pusher subscription error: ${status.status}`));
        pusher.disconnect();
        fallbackToIntervalChannel();
      });
    } else {
      fallbackToIntervalChannel();
    }

    return () => {
      refreshTriggerChannel?.drop();
    };
  }, [
    state.departmentInfo?.id,
    state.departmentInfo?.settings?.isPusherEnabled,
    state.departmentInfo?.settings?.isPullEnabled,
    dispatch,
  ]);

  if (isLoading) {
    return <LottieLogoPageLoader />;
  } else if (!isLoaded) {
    return <ErrorPage />;
  }
  return children;
};
